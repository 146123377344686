import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import Table from 'components/Motion_Detection/Alarm_FTP_Upload/Table';
import NavButtons from 'components/Motion_Detection/Alarm_FTP_Upload/NavButtons';
import ForumBox from 'components/Motion_Detection/Alarm_FTP_Upload/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Alarm FTP Upload - 1080p Series Cameras",
  "path": "/Motion_Detection/Alarm_FTP_Upload/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Set the FTP upload during alarm and photo series",
  "image": "./MD_SearchThumb_FTPSettingsAlarmUpload_1080p.png",
  "social": "/images/Search/MD_SearchThumb_FTPSettingsAlarmUpload_1080p.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_MD-FTP-Upload_white.webp",
  "chapter": "Motion Detection"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Alarm FTP Upload - 1080p Series Cameras' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Set the FTP upload during alarm and photo series' image='/images/Search/MD_SearchThumb_FTPSettingsAlarmUpload_1080p.png' twitter='/images/Search/MD_SearchThumb_FTPSettingsAlarmUpload_1080p.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Bewegungserkennung/Alarm_FTP_Upload/' locationFR='/fr/Motion_Detection/Alarm_FTP_Upload/' crumbLabel="FTP Upload" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "alarm-ftp-upload",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#alarm-ftp-upload",
        "aria-label": "alarm ftp upload permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm FTP Upload`}</h2>
    <h2 {...{
      "id": "ftp-settings-for-1080p-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ftp-settings-for-1080p-cameras",
        "aria-label": "ftp settings for 1080p cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`FTP Settings for 1080p Cameras`}</h2>
    <p><strong parentName="p">{`Set the FTP upload during alarm and photo series`}</strong></p>
    <p>{`Your IP camera can upload pictures and videos either during a motion alarm or in a time interval. The configuration can be set in the web interface of your camera inside the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/FTP/"
      }}>{`FTP Settings`}</a>{` menu.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/45cf4dd72d0075cccff45c4125af15a5/0a47e/Alarm_FTP_1080p_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABhUlEQVQoz52RTUtbURCGz09tV0KhC6Eu+icaBNtFXVUFLQm0SLNw020VCiVICH6keJNIeyX3zk1uzsfMOWdGvFdDCkmgvjybA/Ocd2DU1nF3s91/9fVi89vV6+PLl83eElqPvGj2Nr5cvGlfvz3pb7WvVTdJE3BJbhKwA7DDYgkDMHMSMDeZuanm1aQA4RgJRVhWJJBDo52ehXqMo8QgIirPwSFpYyNzZFmKdY7Ikw8L+MiiAArvKcYoq2Ot1Vr7pxCRc+6hGaAIISDiGt9VCQsholoGRNRaO+e896tkYwwizssRsZLzvG4movWyMWbe/CQD1A9mXrN2WZb15vNyZlaQA6HTs6mz+uEG/4ZZArOxdjIttTaBqMYjCrMqAKohlv+PKrKx9Frc2ZPzA+l8Epo9Vla/DQrXPL87ucxa3fHZ72y8f5juH90dHKV7h4ykJvlYfn3kH+/kdFtOG+Kmi3I/M7tnt5876e7PP9976d/tD6PGzm1jZ9R4z4hqNBw+b+1ZWd4DBikS/qZOk04AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/45cf4dd72d0075cccff45c4125af15a5/e4706/Alarm_FTP_1080p_01.avif 230w", "/en/static/45cf4dd72d0075cccff45c4125af15a5/d1af7/Alarm_FTP_1080p_01.avif 460w", "/en/static/45cf4dd72d0075cccff45c4125af15a5/56a33/Alarm_FTP_1080p_01.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/45cf4dd72d0075cccff45c4125af15a5/a0b58/Alarm_FTP_1080p_01.webp 230w", "/en/static/45cf4dd72d0075cccff45c4125af15a5/bc10c/Alarm_FTP_1080p_01.webp 460w", "/en/static/45cf4dd72d0075cccff45c4125af15a5/e88ff/Alarm_FTP_1080p_01.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/45cf4dd72d0075cccff45c4125af15a5/81c8e/Alarm_FTP_1080p_01.png 230w", "/en/static/45cf4dd72d0075cccff45c4125af15a5/08a84/Alarm_FTP_1080p_01.png 460w", "/en/static/45cf4dd72d0075cccff45c4125af15a5/0a47e/Alarm_FTP_1080p_01.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/45cf4dd72d0075cccff45c4125af15a5/0a47e/Alarm_FTP_1080p_01.png",
              "alt": "Alarm FTP Upload Setup for the 1080p Camera Series",
              "title": "Alarm FTP Upload Setup for the 1080p Camera Series",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`After setting your FTP configuration, continue with our `}<a parentName="p" {...{
        "href": "/en/Motion_Detection/Setup/"
      }}>{`Motion Detection Setup Guide`}</a>{` to activate the FTP upload in case of an alarm event.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <Table mdxType="Table" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Next please apply all settings and test them afterwards to make sure the settings are correct.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      